import { ReactComponent as NotesSVG } from "../../svg/notes.svg";
import styles from "./globalLoader.module.css";

const GlobalLoader = () => {
  return (
    <div className={styles.globalLoader}>
      <NotesSVG />
    </div>
  );
};

export default GlobalLoader;
