import { useTranslation } from "react-i18next";
import Button from "../../../../components/button/button";
import Container from "../../../../components/container/container";
import Typography from "../../../../components/typography/typography";
import { ReactComponent as ArrowRightSVG } from "../../../../svg/arrow-right.svg";
import styles from "./events.module.css";
import { Event } from "../../../../types/Event";
import EventCard from "../../../../components/eventCard/eventCard";
import { Link } from "react-router-dom";
import { routes } from "../../../../constants/routes";

const EventsSection = ({ events = [] }: { events: Event[] }) => {
  const { t } = useTranslation();

  const renderEventsList = events?.map((event, index) => (
    <EventCard
      key={event._id}
      id={event._id}
      reverse={index % 2 !== 0}
      title={event.title}
      description={event.shortDescription}
      picture={`${process.env.REACT_APP_SERVER_URL}${event.pictureUrl}`}
      date={event.startEventDate}
    />
  ));

  return (
    <section>
      <Container>
        <div className={styles.sectionContent}>
          <div className={styles.title}>
            <Typography
              color="white"
              variant="h2"
              size={48}
              weight={500}
              uppercase
            >
              {t("landing.eventsSection.comming")}{" "}
              <Typography
                color="yellow"
                variant="span"
                size={48}
                weight={500}
                uppercase
              >
                {t("landing.eventsSection.soon")} 🔥
              </Typography>
            </Typography>
          </div>

          <div className={styles.list}>{renderEventsList}</div>
          <div className={styles.bottomButton}>
            <Link to={routes.events}>
              <Button color="dark" icon={<ArrowRightSVG />}>
                {t("landing.eventsSection.moreEventsButton")}
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default EventsSection;
