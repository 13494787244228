import styles from "./standWithUkraine.module.css";

const StandWithUkraine = () => {
  return (
    <div className={styles.marquee}>
      <div className={styles.marquee__inner}>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        <p className={styles.marquee__line}> STAND WITH UKRAINE </p>
        
      </div>
    </div>
  );
};

export default StandWithUkraine;
