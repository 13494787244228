import Container from "../container/container";
import styles from "./footer.module.css";
import { ReactComponent as LogoSVG } from "../../svg/logo.svg";
import { ReactComponent as PhoneSVG } from "../../svg/phone.svg";
import Typography from "../typography/typography";
import { useTranslation } from "react-i18next";
import { routes } from "../../constants/routes";
import { NavLink } from "react-router-dom";
import Button from "../button/button";
import { ReactComponent as FacebookSVG } from "../../svg/facebook.svg";
import { ReactComponent as YoutubeSVG } from "../../svg/youtube.svg";
import { ReactComponent as TelegramSVG } from "../../svg/telegram.svg";
import { ReactComponent as InstagramSVG } from "../../svg/instagram.svg";
import { Entrypoint } from "../../types/Entrypoint";

interface IFooter {
  entrypoint: Entrypoint | null;
}

const Footer = ({ entrypoint }: IFooter) => {
  const { t } = useTranslation();

  const links = [
    {
      path: routes.main,
      label: t("global.navigation.main"),
    },
    {
      path: routes.about,
      label: t("global.navigation.aboutUs"),
    },
    {
      path: routes.events,
      label: t("global.navigation.events"),
    },
    {
      path: routes.news,
      label: t("global.navigation.news"),
    },
    {
      path: routes.media,
      label: t("global.navigation.media"),
    },
  ];

  const renderLinks = links?.map((item) => (
    <NavLink key={item.path} to={item.path}>
      <Typography size={14} weight={400} color="silver" variant="span">
        {item.label}
      </Typography>
    </NavLink>
  ));

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerContent}>
          <div className={styles.logo}>
            <LogoSVG width={40} height={40} />
            <Typography variant="span" weight={500} size={20} color="silver">
              {t("global.appName")}
            </Typography>
          </div>
          <div className={styles.links}>{renderLinks}</div>
          <div className={styles.right}>
            <div className={styles.address}>
              <Typography weight={400} size={14} color="silver" align="right">
                {entrypoint?.about.address}
              </Typography>
            </div>
            <div className={styles.socialButtons}>
              {entrypoint?.about?.facebookLink !== "" && (
                <a
                  href={entrypoint?.about?.facebookLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button size="small" color="dark" icon={<FacebookSVG />} />
                </a>
              )}
              {entrypoint?.about?.youtubeLink !== "" && (
                <a
                  href={entrypoint?.about?.youtubeLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="small" color="dark" icon={<YoutubeSVG />} />
                </a>
              )}
              {entrypoint?.about?.telegramLink !== "" && (
                <a
                  href={entrypoint?.about?.telegramLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="small" color="dark" icon={<TelegramSVG />} />
                </a>
              )}
              {entrypoint?.about?.instagramLink !== "" && (
                <a
                  href={entrypoint?.about?.instagramLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="small" color="dark" icon={<InstagramSVG />} />
                </a>
              )}
            </div>
            <Button size="small" icon={<PhoneSVG />}>
              {t("landing.heroSection.beginButton")}
            </Button>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
