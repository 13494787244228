export const resources = {
  en: {
    translation: {
      global: require("./locales/en/global.json"),
      landing: require("./locales/en/landing.json"),
    },
  },
  ua: {
    translation: {
      global: require("./locales/ua/global.json"),
      landing: require("./locales/ua/landing.json"),
    },
  },
};
