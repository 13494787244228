import { ReactNode, useEffect } from "react";
import styles from "./modal.module.css";

interface IModalProps {
  opened: boolean;
  children: ReactNode;
  header?: ReactNode;
}

const Modal = ({ opened, children, header }: IModalProps) => {
  useEffect(() => {
    document.body.style.overflow = opened ? "hidden" : "auto";
  }, [opened]);

  if (!opened) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.modalBox}>
        {header && <div className={styles.header}>{header}</div>}

        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
