import classNames from "classnames";
import Picture from "../picture/picture";
import Typography from "../typography/typography";
import styles from "./eventCard.module.css";
import Button from "../button/button";
import { ReactComponent as ArrowRightSVG } from "../../svg/arrow-right.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";

export interface IEventCard {
  title: string;
  description: string;
  date: string;
  id: string;
  picture: string;
  reverse?: boolean;
}

const EventCard = ({
  title,
  description,
  date,
  picture,
  id,
  reverse = false,
}: IEventCard) => {
  const { t } = useTranslation();

  const startDate = new Date(date);

  const currentDate = new Date();

  const isComplete = startDate < currentDate;

  return (
    <div
      className={classNames(styles.eventCard, {
        [styles.reverse]: reverse,
        [styles.completed]: isComplete,
      })}
    >
      <Picture
        className={styles.picture}
        size={340}
        url={picture}
        alt={picture}
      />
      <div className={styles.content}>
        <Typography
          variant="h2"
          size={36}
          weight={500}
          color="yellow"
          align={reverse ? "right" : "left"}
          uppercase
        >
          {title}
        </Typography>
        <Typography
          variant="label"
          size={20}
          weight={500}
          color={isComplete ? "red" : "white"}
          align={reverse ? "right" : "left"}
          uppercase
        >
          {isComplete ? t("global.expired") : date}
        </Typography>
        <Typography
          align={reverse ? "right" : "left"}
          variant="p"
          size={18}
          color="grey"
        >
          {description}
        </Typography>
        <div className={styles.button}>
          <Link to={routes.event.replace(":id", id)}>
            <Button disabled={isComplete} icon={<ArrowRightSVG />}>
              {t("global.readMore")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
