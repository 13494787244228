import { memo } from "react";
import Picture from "../picture/picture";
import styles from "./newCard.module.css";
import Typography from "../typography/typography";
import Button from "../button/button";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRightSVG } from "../../svg/arrow-right.svg";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";

export interface INewCardProps {
  picture: string;
  title: string;
  id: string;
  description: string;
  author: string;
  date: string;
}

const NewCard = ({
  picture,
  date,
  author,
  id,
  title,
  description,
}: INewCardProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.newCard}>
      <Picture
        className={styles.picture}
        size={400}
        url={picture}
        alt={picture}
      />

      <div className={styles.content}>
        <Typography
          variant="h2"
          size={36}
          weight={500}
          color="yellow"
          align="center"
          uppercase
        >
          {title}
        </Typography>
        <Typography
          variant="label"
          size={20}
          weight={500}
          color="white"
          align="center"
          uppercase
        >
          {date}
        </Typography>
        <Typography align="center" variant="p" size={18} color="grey">
          {description}
        </Typography>
        <div className={styles.footerCard}>
          <Link to={routes.new.replace(":id", id)}>
            <Button icon={<ArrowRightSVG />}>{t("global.readMore")}</Button>
          </Link>
          <Typography
            className={styles.authorText}
            color="white"
            variant="span"
          >
            {t("global.author")}:{" "}
            <Typography color="yellow" variant="span">
              {author}
            </Typography>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default memo(NewCard);
