import { useTranslation } from "react-i18next";
import Typography from "../../components/typography/typography";
import styles from "./aboutUs.module.css";
import Container from "../../components/container/container";
import { useOutletContext } from "react-router-dom";
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { ReactComponent as FacebookSVG } from "../../svg/facebook.svg";
import { ReactComponent as YoutubeSVG } from "../../svg/youtube.svg";
import { ReactComponent as TelegramSVG } from "../../svg/telegram.svg";
import { ReactComponent as InstagramSVG } from "../../svg/instagram.svg";

import { Entrypoint } from "../../types/Entrypoint";
import Button from "../../components/button/button";
import Picture from "../../components/picture/picture";

const AboutUs = () => {
  const { t } = useTranslation();

  const {
    entrypoint: { about },
  }: { entrypoint: Entrypoint } = useOutletContext();

  return (
    <Container>
      <div className={styles.aboutUs}>
        <Picture
          className={styles.picture}
          size={400}
          url={`${process.env.REACT_APP_SERVER_URL}${about?.pictureUrl}`}
          alt={t("landing.aboutUsSection.pictureAlt")}
        />
        <div className={styles.title}>
          <Typography
            color="white"
            variant="h2"
            size={48}
            weight={500}
            uppercase
          >
            {t("landing.aboutUsSection.briefly")}{" "}
            <Typography
              color="yellow"
              variant="span"
              size={48}
              weight={500}
              uppercase
            >
              {t("landing.aboutUsSection.aboutUs")}
            </Typography>
          </Typography>
          <Typography color="grey" variant="p" size={16} weight={400}>
            {about.description}
          </Typography>
        </div>

        <Typography
          color="yellow"
          variant="span"
          size={24}
          weight={500}
          uppercase
        >
          {t("global.ourAddress")}
        </Typography>
        <Typography
          className={styles.addressText}
          color="silver"
          variant="p"
          size={14}
          weight={400}
        >
          {about.address}
        </Typography>
        <Map
          mapboxAccessToken="pk.eyJ1IjoiY2hhbmVsc3Bvbmt5IiwiYSI6ImNsanI4dHoxZjAxdGwzZG11ZTZkamlla3gifQ.urCeRddOSRSQOtAqOwx0jQ"
          initialViewState={{
            longitude: 33.2943892,
            latitude: 47.8965343,
            zoom: 16,
          }}
          style={{ maxWidth: 600, height: 400, marginTop: "20px" }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
        />
        <Typography
          color="yellow"
          variant="p"
          size={24}
          weight={500}
          uppercase
          className={styles.socialLinksTitle}
        >
          {t("global.socialLinks")}
        </Typography>
        <div className={styles.socialButtons}>
          {about?.facebookLink !== "" && (
            <a href={about?.facebookLink} rel="noreferrer" target="_blank">
              <Button size="small" color="dark" icon={<FacebookSVG />} />
            </a>
          )}
          {about?.youtubeLink !== "" && (
            <a href={about?.youtubeLink} target="_blank" rel="noreferrer">
              <Button size="small" color="dark" icon={<YoutubeSVG />} />
            </a>
          )}
          {about?.telegramLink !== "" && (
            <a href={about?.telegramLink} target="_blank" rel="noreferrer">
              <Button size="small" color="dark" icon={<TelegramSVG />} />
            </a>
          )}
          {about?.instagramLink !== "" && (
            <a href={about?.instagramLink} target="_blank" rel="noreferrer">
              <Button size="small" color="dark" icon={<InstagramSVG />} />
            </a>
          )}
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;
