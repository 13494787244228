import { CSSProperties, ReactNode, memo } from "react";
import styles from "./container.module.css";

interface IContainerProps {
  children: ReactNode;
  size?: "xs" | "md";
}

const Container = ({ children, size = "md" }: IContainerProps) => {
  const style: CSSProperties = {
    maxWidth: size === "xs" ? "1000px" : "1570px",
    margin: "0 auto",
  };

  return (
    <div className={styles.container} style={style}>
      {children}
    </div>
  );
};

export default memo(Container);
