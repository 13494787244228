import { useTranslation } from "react-i18next";
import styles from "./news.module.css";
import Container from "../../../../components/container/container";
import Typography from "../../../../components/typography/typography";
import Button from "../../../../components/button/button";
import { ReactComponent as ArrowRightSVG } from "../../../../svg/arrow-right.svg";
import NewCard from "../../../../components/newCard/newCard";
import { New } from "../../../../types/New";
import { Link } from "react-router-dom";
import { routes } from "../../../../constants/routes";

const NewsSection = ({ news }: { news: New[] }) => {
  const { t } = useTranslation();

  const renderNewsList = news?.map((item) => (
    <NewCard
      id={item._id}
      key={item._id}
      title={item.title}
      date="date"
      author={item.authorId}
      picture={`${process.env.REACT_APP_SERVER_URL}${item.pictureUrl}`}
      description={item.shortDescription}
    />
  ));

  return (
    <section className={styles.news}>
      <Container>
        <div className={styles.sectionContent}>
          <Typography
            className={styles.title}
            color="yellow"
            variant="h2"
            size={48}
            weight={500}
            uppercase
            align="center"
          >
            {t("landing.newsSection.title")}
          </Typography>
          <div className={styles.list}>{renderNewsList}</div>
          <div className={styles.bottomButton}>
            <Link to={routes.news}>
              <Button color="dark" icon={<ArrowRightSVG />}>
                {t("landing.newsSection.moreNewsButton")}
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default NewsSection;
