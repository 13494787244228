import Button from "../../../../components/button/button";
import Container from "../../../../components/container/container";
import Picture from "../../../../components/picture/picture";
import Typography from "../../../../components/typography/typography";

import { ReactComponent as FacebookSVG } from "../../../../svg/facebook.svg";
import { ReactComponent as YoutubeSVG } from "../../../../svg/youtube.svg";
import { ReactComponent as TelegramSVG } from "../../../../svg/telegram.svg";
import { ReactComponent as ArrowRightSVG } from "../../../../svg/arrow-right.svg";
import { ReactComponent as InstagramSVG } from "../../../../svg/instagram.svg";

import styles from "./aboutUs.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "../../../../constants/routes";

interface IAboutUsProps {
  picture: string;
  description: string;
  facebookURL: string;
  telegramURL: string;
  youtubeURL: string;
  instagramURL: string;
}

const AboutUsSection = ({
  picture,
  description,
  facebookURL,
  telegramURL,
  youtubeURL,
  instagramURL,
}: IAboutUsProps) => {
  const { t } = useTranslation();

  return (
    <section id="aboutUsSection" className={styles.aboutUsSection}>
      <Container>
        <div className={styles.sectionContent}>
          <Picture
            className={styles.picture}
            size={650}
            url={picture}
            alt={t("landing.aboutUsSection.pictureAlt")}
          />
          <div className={styles.content}>
            <Typography
              color="white"
              variant="h2"
              size={48}
              weight={500}
              uppercase
            >
              {t("landing.aboutUsSection.briefly")}{" "}
              <Typography
                color="yellow"
                variant="span"
                size={48}
                weight={500}
                uppercase
              >
                {t("landing.aboutUsSection.aboutUs")}
              </Typography>
            </Typography>
            <Typography color="grey" variant="p" size={18} weight={400}>
              {description}
            </Typography>
            <div className={styles.socialButtons}>
              {facebookURL !== "" && (
                <a href={facebookURL} target="_blank">
                  <Button color="dark" icon={<FacebookSVG />} />
                </a>
              )}
              {youtubeURL !== "" && (
                <a href={youtubeURL} target="_blank">
                  <Button color="dark" icon={<YoutubeSVG />} />
                </a>
              )}
              {telegramURL !== "" && (
                <a href={telegramURL} target="_blank">
                  <Button color="dark" icon={<TelegramSVG />} />
                </a>
              )}
              {instagramURL !== "" && (
                <a href={instagramURL} target="_blank">
                  <Button color="dark" icon={<InstagramSVG />} />
                </a>
              )}
              <Link to={routes.about}>
                <Button color="purple" icon={<ArrowRightSVG />}>
                  {t("landing.aboutUsSection.readMoreButton")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutUsSection;
