import AboutUsSection from "./sections/aboutUs/aboutUs";
import EventsSection from "./sections/events/events";
import HeroSection from "./sections/hero/hero";
import MediaSection from "./sections/media/media";
import NewsSection from "./sections/news/news";
import { Entrypoint } from "../../types/Entrypoint";
import { Event } from "../../types/Event";
import { New } from "../../types/New";
import { Media } from "../../types/Media";
import { useOutletContext } from "react-router-dom";

const LandingPage = () => {
  const { entrypoint }: { entrypoint: Entrypoint } = useOutletContext();

  return (
    <>
      <HeroSection
        picture={
          `${process.env.REACT_APP_SERVER_URL}${entrypoint?.hero.pictureUrl}` ||
          "pictures/hero.png"
        }
        title={entrypoint?.hero.title || ""}
        smallQuote={entrypoint?.hero.smallQuote || ""}
        description={entrypoint?.hero.description || ""}
      />
      <AboutUsSection
        picture={
          `${process.env.REACT_APP_SERVER_URL}${entrypoint?.about.pictureUrl}` ||
          "pictures/hero.png"
        }
        instagramURL={entrypoint?.about.instagramLink || ""}
        youtubeURL={entrypoint?.about.youtubeLink || ""}
        telegramURL={entrypoint?.about.telegramLink || ""}
        facebookURL={entrypoint?.about.facebookLink || ""}
        description={entrypoint?.about.description || ""}
      />
      <EventsSection events={entrypoint?.events as Event[]} />
      <NewsSection news={entrypoint?.news as New[]} />
      <MediaSection media={entrypoint?.media as Media[]} />
    </>
  );
};

export default LandingPage;
