import { Outlet } from "react-router";
import Header from "../components/header/header";
import StandWithUkraine from "../components/standWithUkraine/standWithUkraine";
import Footer from "../components/footer/footer";
import { Entrypoint } from "../types/Entrypoint";
import { useEffect, useState } from "react";
import { axiosInstance } from "../axios/instance";
import { apis } from "../axios/apis";
import GlobalLoader from "../components/globalLoader/globalLoader";

export const Root = () => {
  const [entrypoint, setEntrypoint] = useState<Entrypoint | null>(null);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchGlobalSettings();
  }, []);

  const fetchGlobalSettings = async () => {
    try {
      const entrypoint = await axiosInstance.get(apis.entrypoint);

      setEntrypoint(entrypoint.data as Entrypoint);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  return (
    <>
      <StandWithUkraine />
      <Header />
      <Outlet context={{ entrypoint }} />
      <Footer entrypoint={entrypoint} />
    </>
  );
};
