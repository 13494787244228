import { NavLink } from "react-router-dom";
import styles from "./header.module.css";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useEffect, useState } from "react";
import Typography from "../typography/typography";
import { ReactComponent as HumburgerSVG } from "../../svg/hamburger.svg";
import { ReactComponent as CloseSVG } from "../../svg/close.svg";
import classNames from "classnames";

const NavLinks = () => {
  const { t } = useTranslation();
  const [isShowMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isShowMobileMenu ? "hidden" : "auto";
  }, [isShowMobileMenu]);

  const links = [
    {
      path: routes.main,
      label: t("global.navigation.main"),
    },
    {
      path: routes.about,
      label: t("global.navigation.aboutUs"),
    },
    {
      path: routes.events,
      label: t("global.navigation.events"),
    },
    {
      path: routes.news,
      label: t("global.navigation.news"),
    },
    {
      path: routes.media,
      label: t("global.navigation.media"),
    },
  ];

  const toggleMobileMenu = useCallback(
    () => setShowMobileMenu(!isShowMobileMenu),
    [isShowMobileMenu]
  );

  const renderLinks = links?.map((item) => (
    <NavLink
      key={item.path}
      to={item.path}
      className={({ isActive }) => (isActive ? styles.activeLink : "")}
    >
      <Typography size={20} weight={500} color="silver" variant="span">
        {item.label}
      </Typography>
    </NavLink>
  ));

  const renderMobileMenu = (
    <div
      className={classNames(styles.mobileNavLinks, {
        [styles.isShowMobileMenu]: isShowMobileMenu,
      })}
    >
      <div className={styles.closeButton} onClick={toggleMobileMenu}>
        <CloseSVG />
      </div>
      {renderLinks}
    </div>
  );

  return (
    <>
      <div className={styles.navLinks}>{renderLinks}</div>
      <div className={styles.humburgerButton}>
        <HumburgerSVG onClick={toggleMobileMenu} />
        {renderMobileMenu}
      </div>
    </>
  );
};

export default memo(NavLinks);
