import { createBrowserRouter } from "react-router-dom";
import { Root } from "./root";
import LandingPage from "../pages/landing/landing";
import { routes } from "../constants/routes";
import Events from "../pages/events/events";
import News from "../pages/news/news";
import AboutUs from "../pages/aboutUs/aboutUs";
import Media from "../pages/media/media";
import Event from "../pages/event/event";
import Article from "../pages/article/article";

export const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: routes.main,
        element: <LandingPage />,
      },
      {
        path: routes.about,
        element: <AboutUs />,
      },
      {
        path: routes.events,
        element: <Events />,
      },
      {
        path: routes.event,
        element: <Event />,
      },
      {
        path: routes.news,
        element: <News />,
      },
      {
        path: routes.new,
        element: <Article />,
      },
      {
        path: routes.media,
        element: <Media />,
      },
    ],
  },
]);
