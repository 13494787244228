import { CSSProperties, memo, useState } from "react";
import styles from "./picture.module.css";
import classNames from "classnames";

interface IPictureProps {
  url: string;
  alt: string;
  size?: number;
  className?: string;
}

const Picture = ({ url, alt, className, size = 200 }: IPictureProps) => {
  const [isLoad, setLoad] = useState(true);

  const onLoad = () => setLoad(false);

  const styleSize: CSSProperties = {
    width: `${size}px`,
    height: `${size}px`,
  };

  const style: CSSProperties = {
    ...styleSize,
    objectFit: "cover",
    objectPosition: "center",
  };

  return (
    <div style={styleSize} className={classNames([styles.picture, className])}>
      {isLoad && <div className={styles.skeleton} />}
      <img style={style} src={url} alt={alt} onLoad={onLoad} />
    </div>
  );
};

export default memo(Picture);
