import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { New } from "../../types/New";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import GlobalLoader from "../../components/globalLoader/globalLoader";
import Container from "../../components/container/container";
import styles from "./news.module.css";
import Typography from "../../components/typography/typography";
import NewCard from "../../components/newCard/newCard";

const News = () => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [news, setNews] = useState<New[]>([]);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const { data } = await axiosInstance.get(apis.news);

      setNews(data as New[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  const renderNews = news.map((item) => (
    <NewCard
      date="date"
      picture={`${process.env.REACT_APP_SERVER_URL}${item.pictureUrl}`}
      description={item.shortDescription}
      id={item._id}
      title={item.title}
      author={item.authorId}
    />
  ));

  return (
    <div className={styles.news}>
      <Container>
        <div className={styles.title}>
          <Typography
            className={styles.title}
            color="yellow"
            variant="h2"
            size={48}
            weight={500}
            uppercase
            align="center"
          >
            {t("landing.newsSection.title")}
          </Typography>
        </div>
        <div className={styles.newsList}>{renderNews}</div>
      </Container>
    </div>
  );
};

export default News;
