import { memo } from "react";
import { ReactComponent as LogoSVG } from "../../svg/logo.svg";
import styles from "./header.module.css";
import Container from "../container/container";
import Typography from "../typography/typography";
import NavLinks from "./navLinks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";

const Header = () => {
  const { t } = useTranslation();

  return (
    <header className={styles.header}>
      <Container>
        <div className={styles.headerContent}>
          <Link to={routes.main} className={styles.logo}>
            <LogoSVG width={40} height={40} />
            <Typography variant="span" weight={500} size={20} color="silver">
              {t("global.appName")}
            </Typography>
          </Link>
          <NavLinks />
          {/* <div className={styles.signinButton}>
            <Button color="dark" size="small">
              {t("global.signin")}
            </Button>
          </div> */}
        </div>
      </Container>
    </header>
  );
};

export default memo(Header);
