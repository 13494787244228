import { useTranslation } from "react-i18next";
import Container from "../../../../components/container/container";
import Typography from "../../../../components/typography/typography";
import styles from "./media.module.css";
import Picture from "../../../../components/picture/picture";
import { ReactComponent as ArrowRightSVG } from "../../../../svg/arrow-right.svg";
import Button from "../../../../components/button/button";
import { Media } from "../../../../types/Media";
import { Link } from "react-router-dom";
import { routes } from "../../../../constants/routes";

const MediaSection = ({ media }: { media: Media[] }) => {
  const { t } = useTranslation();

  const renderTypeContent = (
    key: Media["type"] | undefined,
    url: string | undefined
  ) => {
    switch (key) {
      case "picture":
        return (
          <Picture
            className={styles.picture}
            url={`${process.env.REACT_APP_SERVER_URL}${url}`}
            alt="1"
          />
        );

      case "youtube":
        return (
          <iframe
            width="100%"
            height="100%"
            style={{ border: "none" }}
            src={url}
            title={url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        );
      default:
        return null;
    }
  };

  const renderMedia = media?.map((item) => (
    <div className={styles.content}>
      {renderTypeContent(item.type, item.url)}
    </div>
  ));

  return (
    <section>
      <Container>
        <div className={styles.sectionContent}>
          <Typography
            className={styles.title}
            color="yellow"
            variant="h2"
            size={48}
            weight={500}
            uppercase
            align="center"
          >
            {t("landing.mediaSection.title")}
          </Typography>

          <div className={styles.mediaContent}>
            <div className={styles.mediaList}>{renderMedia}</div>
          </div>
          <div className={styles.bottomButton}>
            <Link to={routes.media}>
              <Button color="dark" icon={<ArrowRightSVG />}>
                {t("landing.mediaSection.moreMediaButton")}
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default MediaSection;
