import styles from "./input.module.css";

interface IInputProps {
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
}

const Input = ({ placeholder, value, onChange = () => null }: IInputProps) => {
  return (
    <input
      className={styles.input}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};

export default Input;
