import { useParams } from "react-router-dom";
import styles from "./event.module.css";
import Container from "../../components/container/container";
import Typography from "../../components/typography/typography";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import { Event as EventType } from "../../types/Event";
import GlobalLoader from "../../components/globalLoader/globalLoader";
import Picture from "../../components/picture/picture";

const Event = () => {
  const params = useParams();
  const [event, setEvent] = useState<EventType>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEvent = async () => {
    if (!params.id) return;

    try {
      const { data } = await axiosInstance.get(
        apis.event.replace(":id", params.id)
      );

      setEvent(data as EventType);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  return (
    <div className={styles.event}>
      <Container size="xs">
        <div className={styles.eventContainer}>
          <Picture
            className={styles.picture}
            size={340}
            url={`${process.env.REACT_APP_SERVER_URL}${event?.pictureUrl}`}
            alt={"Event Picture"}
          />
          <Typography
            color="silver"
            variant="h2"
            size={48}
            weight={300}
            uppercase
            className={styles.title}
          >
            {event?.title}
          </Typography>
          <Typography color="grey" variant="h4" size={16} weight={500}>
            {event?.startEventDate}
          </Typography>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: `${event?.content}` }}
          />
        </div>
      </Container>
    </div>
  );
};

export default Event;
