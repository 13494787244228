import { useEffect, useState } from "react";
import Container from "../../components/container/container";
import Typography from "../../components/typography/typography";
import styles from "./events.module.css";
import { useTranslation } from "react-i18next";
import { Event } from "../../types/Event";
import EventCard from "../../components/eventCard/eventCard";
import GlobalLoader from "../../components/globalLoader/globalLoader";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";

const Events = () => {
  const { t } = useTranslation();

  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const { data } = await axiosInstance.get(apis.events);

      setEvents(data as Event[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  const renderEvents = events.map((item, index) => (
    <EventCard
      key={item._id}
      reverse={index % 2 !== 0}
      picture={`${process.env.REACT_APP_SERVER_URL}${item.pictureUrl}`}
      date={item.startEventDate}
      description={item.shortDescription}
      title={item.title}
      id={item._id}
    />
  ));

  return (
    <div className={styles.events}>
      <Container>
        <div className={styles.title}>
          <Typography
            color="white"
            variant="h2"
            size={48}
            weight={500}
            uppercase
          >
            {t("landing.eventsSection.comming")}{" "}
            <Typography
              color="yellow"
              variant="span"
              size={48}
              weight={500}
              uppercase
            >
              {t("landing.eventsSection.soon")} 🔥
            </Typography>
          </Typography>
        </div>
        <div className={styles.eventsList}>{renderEvents}</div>
      </Container>
    </div>
  );
};

export default Events;
