import { useTranslation } from "react-i18next";
import Button from "../../../../components/button/button";
import Container from "../../../../components/container/container";
import Picture from "../../../../components/picture/picture";
import Typography from "../../../../components/typography/typography";
import { ReactComponent as ArrowRightSVG } from "../../../../svg/arrow-right.svg";
import { ReactComponent as PhoneSVG } from "../../../../svg/phone.svg";
import styles from "./hero.module.css";
import Modal from "../../../../components/modal/modal";
import Input from "../../../../components/input/input";
import { useState } from "react";
import { axiosInstance } from "../../../../axios/instance";
import { apis } from "../../../../axios/apis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AxiosError, AxiosResponse } from "axios";

export interface IHeroSectionProps {
  description: string;
  smallQuote: string;
  title: string;
  picture: string;
}

const HeroSection = ({
  title,
  smallQuote,
  picture,
  description,
}: IHeroSectionProps) => {
  const { t } = useTranslation();
  const [isOpenModal, setOpenModal] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const onMoreButton = () => {
    const targetElement = document.getElementById("aboutUsSection");

    if (!targetElement) return;

    targetElement.scrollIntoView({ behavior: "smooth" });
  };

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);

    const phoneRegex = /^\+380\d{9}$/;
    setError(
      phoneRegex.test(value) ? false : "Туц-пам ! Номер телефону не коректний"
    );
  };

  const handleSubmit = async () => {
    try {
      await axiosInstance.post(apis.booking, {
        name,
        phoneNumber,
      });

      toast.success("Ви успішно записалися ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      setError(false);
      setName("");
      setPhoneNumber("");
      setOpenModal(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error);

        if (
          (error.response as AxiosResponse).data.message ===
          "phone_number_already_exists"
        ) {
          toast.error("Ви вже записувались на урок", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      }
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        header={
          <Typography color="white">
            {t("landing.heroSection.callModalTitle")}
          </Typography>
        }
        opened={isOpenModal}
      >
        <div className={styles.modal}>
          <Input placeholder="Імя" onChange={setName} />
          <Input
            onChange={handlePhoneNumberChange}
            value={phoneNumber}
            placeholder="Номер телефону"
          />
          <Typography size={12} color="silver">
            Заявка розглядається до 24 годин
          </Typography>

          {error && (
            <Typography size={12} color="red">
              {error}
            </Typography>
          )}

          <Button
            onClick={handleSubmit}
            disabled={
              Boolean(error) || name.trim() === "" || phoneNumber.trim() === ""
            }
            size="small"
          >
            Записатися
          </Button>
          <Button size="small" color="dark" onClick={() => setOpenModal(false)}>
            Закрити
          </Button>
        </div>
      </Modal>
      <section className={styles.heroSection}>
        <Container>
          <div className={styles.sectionContent}>
            <div className={styles.leftContent}>
              <div className={styles.smallQuoteBox}>
                <Typography
                  variant="label"
                  size={13}
                  weight={500}
                  color="silver"
                  uppercase
                >
                  {smallQuote}
                </Typography>
              </div>
              <Typography
                variant="h1"
                size={80}
                weight={500}
                color="white"
                uppercase
              >
                {title}
              </Typography>
              <Typography variant="p" color="grey" weight={400}>
                {description}
              </Typography>
              <div className={styles.buttons}>
                <Button icon={<PhoneSVG />} onClick={() => setOpenModal(true)}>
                  {t("landing.heroSection.beginButton")}
                </Button>
                <Button
                  onClick={onMoreButton}
                  icon={<ArrowRightSVG />}
                  color="dark"
                >
                  {t("landing.heroSection.moreButton")}
                </Button>
              </div>
            </div>
            <Picture
              className={styles.picture}
              size={507}
              url={picture}
              alt="Hero image"
            />
          </div>
        </Container>
      </section>
    </>
  );
};

export default HeroSection;
