import { useEffect, useState } from "react";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import { Media } from "../../types/Media";
import GlobalLoader from "../../components/globalLoader/globalLoader";
import Picture from "../../components/picture/picture";
import Container from "../../components/container/container";
import styles from "./media.module.css";
import Typography from "../../components/typography/typography";
import { useTranslation } from "react-i18next";

const MediaPage = () => {
  const { t } = useTranslation();
  const [media, setMedia] = useState<Media[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchMedia();
  }, []);

  const fetchMedia = async () => {
    try {
      setLoading(true);

      const { data } = await axiosInstance.get(apis.medias);

      setMedia(data as Media[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  const renderTypeContent = (
    key: Media["type"] | undefined,
    url: string | undefined
  ) => {
    switch (key) {
      case "picture":
        return (
          <Picture
            className={styles.picture}
            url={`${process.env.REACT_APP_SERVER_URL}${url}`}
            alt="1"
          />
        );

      case "youtube":
        return (
          <iframe
            width="100%"
            height="100%"
            style={{ border: "none" }}
            src={url}
            title={url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        );
      default:
        return null;
    }
  };

  const renderMedia = media.map((item) => (
    <div className={styles.content}>
      {renderTypeContent(item.type, item.url)}
    </div>
  ));

  return (
    <div className={styles.media}>
      <Container>
        <Typography
          className={styles.title}
          color="yellow"
          variant="h2"
          size={48}
          weight={500}
          uppercase
          align="center"
        >
          {t("landing.mediaSection.title")}
        </Typography>
        <div className={styles.mediaContent}>
          <div className={styles.mediaList}>{renderMedia}</div>
        </div>
      </Container>
    </div>
  );
};

export default MediaPage;
