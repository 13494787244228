import { useParams } from "react-router-dom";
import { New } from "../../types/New";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import Container from "../../components/container/container";
import Picture from "../../components/picture/picture";
import Typography from "../../components/typography/typography";
import styles from "./article.module.css";

const Article = () => {
  const params = useParams();

  const [article, setArticle] = useState<New>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchArticle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchArticle = async () => {
    if (!params.id) return;

    try {
      const { data } = await axiosInstance.get(
        apis.article.replace(":id", params.id)
      );

      setArticle(data as New);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.article}>
      <Container size="xs">
        <div className={styles.articleContainer}>
          <Picture
            className={styles.picture}
            size={340}
            url={`${process.env.REACT_APP_SERVER_URL}${article?.pictureUrl}`}
            alt={"Event Picture"}
          />
          <Typography
            color="silver"
            variant="h2"
            size={48}
            weight={300}
            uppercase
            className={styles.title}
          >
            {article?.title}
          </Typography>
          <Typography color="grey" variant="h4" size={16} weight={500}>
            {article?.authorId}
          </Typography>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: `${article?.content}` }}
          />
        </div>
      </Container>
    </div>
  );
};

export default Article;
